.App {
  text-align: center;
  background-color: #282c34;
}

.App-header {
  display: flex;
  min-height: 30vh;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-main {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-footer {
  min-height: 60vh;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-mint {
  display: flex;
  flex-direction: row;
  margin-top: 9px;
}

.App-input-address {
  display: flex;
  flex-direction: row;
  font-size: calc(3px + 2vmin);
}

.App-icon {
  margin-left: 10px;
}

input{
  text-align:center;
}

.App-nft
{
  color: black;
  font-size: calc(3px + 2vmin);
}

.App-addresses {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
}

.App-checkboxes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-checkboxes2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.App-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6px + 2vmin);
  margin-top: 10px;
}

.titleText {
  margin-left: 30px;
}

.clearButton {
  margin-right: 5px;
}

.mintButton {
  margin-left: 5px;
}


/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';